import * as dompack from 'dompack';

require('bootstrap');

import * as whintegration from '@mod-system/js/wh/integration';
(window as any).whintegration = whintegration;
import '@mod-publisher/js/richcontent/all';
import '@mod-publisher/js/analytics/ga4';

import { setupWebHareSite, setupWebHareDialogs, setupWebHareSiteForBootstrap } from '@mod-ww/ts/sites';
// @ts-ignore
import PageOverview from '@mod-ww/pages/overview/overview.es';

// custom components (Lit)
import './components/header-media/header-media';
import './components/pentagon/pentagon';
import './components/slideshow/slideshow';

import './generalis.scss';

import './widgets/widgets';
import './footer/footer';

//vendor
import GLightbox from 'glightbox';

import './pages/projects/projects';
import './pages/team/team';

setupWebHareSite(); // openLinksInNewWindow, setupGoogleRecaptcha
setupWebHareDialogs(); // setupDialogs
setupWebHareSiteForBootstrap(); // adds .btn .btn-primary to .wh-form__button

let supportsPassive = false;
try {
  let opts = Object.defineProperty({}, 'passive', {
    get: function () {
      supportsPassive = true;
    },
  });
  (window as any).addEventListener('test', null, opts);
} catch (e) {}

dompack.onDomReady(() => {
  setupInlineLabelForms(); // add a class to the field parent if the input gets focused, remove when blurred
  setupCopyright();
  GLightbox();
  setupContactButton();
});

function setupContactButton() {
  function onScroll() {
    // add class to <html> if we scrolled near the end of the page
    document.documentElement.classList.toggle(
      'at-bottom',
      window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 32
    );
  }
  window.addEventListener('scroll', (ev) => onScroll(), supportsPassive ? { passive: true } : false);
  onScroll();
}

function setupInlineLabelForms() {
  // add a class to the field parent if the input gets focused, remove when blurred
  dompack.qSA('.js-form-inline-labels .wh-form__textinput').forEach((el) => {
    const inputEl = el as HTMLInputElement;
    const elFieldGroup = el.closest('.wh-form__fieldgroup');

    if (!elFieldGroup) {
      return;
    }

    el.addEventListener('focus', () => {
      elFieldGroup.classList.add('focused');
    });

    el.addEventListener('blur', () => {
      if (!inputEl.value) {
        elFieldGroup.classList.remove('focused');
      }
    });
  });
}

function setupCopyright() {
  dompack.qSA('.curyear').forEach((el) => {
    el.textContent = new Date().getFullYear().toString();
  });
}

dompack.register('.ww-p-overview', (node) => new PageOverview(node));
