import { LitElement, html } from 'lit';
import * as whintegration from '@mod-system/js/wh/integration';
import 'dompack/browserfix/reset.css';
import * as interfaces from '../../shared/js/interfaces';
import Swiper from 'swiper';
import { Navigation, EffectFade } from 'swiper/modules';

class Slideshow extends LitElement {
  // type!: string; // video, image
  // videoid!: string;
  // title!: string;
  // subtitle!: string;
  // ishome!: string;
  // image!: interfaces.Image;
  images!: [interfaces.Image];

  static properties = {
    images: { type: Array },
  };
  //   title: { type: String },
  //   subtitle: { type: String },
  //   type: { type: String },
  //   video: { type: String },
  //   videoid: { type: String },
  //   ishome: { type: String },
  //   image: { type: Object },
  // };

  // forces shadow DOM to be disabled
  createRenderRoot() {
    // console.log(this.ishome == 'true');
    // console.log(this.images);
    return this;
  }
  firstUpdated() {
    const elSwiper = this.renderRoot.querySelector('.swiper') as HTMLElement;
    console.log('🚀 ~ createRenderRoot ~ elSwiper:', elSwiper);
    new Swiper(elSwiper, {
      // modules: [Navigation, EffectFade],
      // loop: true,
      // effect: 'fade',
      // fadeEffect: {
      //   crossFade: true,
      // },
      // speed: 500,
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    });
  }

  // connectedCallback() {
  //   super.connectedCallback();

  //   // addEventListener('keydown', this._handleKeydown);
  // }

  render() {
    return html`
      <div class="w-references__referencescontainer">
        <div class="swiper">
          <div class="c-nav">
            <div class="swiper-button-prev">
              volgende
              <img src="${whintegration.config.imgroot}button-next.png" alt="Volgende" />
            </div>
            <div class="swiper-button-next">
              vorige
              <!-- <img src="[imgroot]button-next.png" alt="Volgende" /> -->
            </div>
          </div>
          <div class="swiper-wrapper">
            ${this.images.map(
              (image) =>
                html` <div class="swiper-slide">
                  <div class="c-slide">
                    <img src="${image.link}" class="c-image" alt="" />
                    <div class="c-textcontainer">[text]</div>
                  </div>
                </div>`
            )}
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('c-slideshow', Slideshow);
