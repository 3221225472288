import { LitElement } from 'lit';
import { html, literal } from 'lit/static-html.js';
import 'dompack/browserfix/reset.css';
import * as interfaces from '../../shared/js/interfaces';
import * as whintegration from '@mod-system/js/wh/integration';

// always put in a <div class="pentagonscontainer">!

class Pentagon extends LitElement {
  title!: string;
  subtitle!: string;
  color!: string;
  icon!: interfaces.Image;
  link!: string;

  static properties = {
    title: { type: String },
    subtitle: { type: String },
    color: { type: String },
    icon: { type: Object },
    link: { type: String },
  };

  // forces shadow DOM to be disabled
  createRenderRoot() {
    return this;
  }

  render() {
    const attr = this.link ? literal`a` : literal`div`;

    return html`<${attr} href="${this.link}" class="c_container c_container--${this.color}">
      <div
        class="c_bgelement"
        style="background-image:url('${whintegration.config.site.assetsfolder}vijfhoeken/usp-shape-${
      this.color
    }.svg')"></div>
      <div class="c_text">
        ${
          this.icon
            ? html` <div class="c_icon">
                <img src="${this.icon.link}" alt="" />
              </div>`
            : ''
        }
        <div class="c_title">${this.title}</div>
        ${this.subtitle ? html`<div class="c_subtitle">${this.subtitle}</div>` : ''}
      </div>
    </${attr}>`;
  }
}

customElements.define('c-pentagon', Pentagon);
