import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import * as googleRecaptcha from '@mod-publisher/js/captcha/google-recaptcha';
import * as dompack from 'dompack';
import * as dialogapi from 'dompack/api/dialog';
import * as dialog from 'dompack/components/dialog';

export function setupWebHareSite() {
  openLinksInNewWindow({ extensions: ['pdf'] });
  googleRecaptcha.setupGoogleRecaptcha();
}

export function setupWebHareDialogs() {
  dialogapi.setupDialogs((options) => dialog.createDialog('ww-dialog', options));
}

export function setupWebHareSiteForBootstrap({ addButtonPrimary = true } = {}) {
  dompack.onDomReady(() => {
    for (const button of dompack.qSA('.wh-form__button')) {
      button.classList.add('btn');
      if (addButtonPrimary) {
        button.classList.add('btn-primary');
      }
    }
  });
}
