import * as dompack from 'dompack';
import $ from 'cash-dom';

dompack.onDomReady(() => {
  $('.js-w-highlightedteam-generalist').on('mouseenter', function (evt) {
    $(this).closest('.w-highlightedteam').addClass('generalist-hover');
    $(this).addClass('hover');
  });

  $('.js-w-highlightedteam-generalist').on('mouseleave', (evt) => {
    $(evt.currentTarget).closest('.w-highlightedteam').removeClass('generalist-hover');
    $(evt.currentTarget).removeClass('hover');
  });
});
