import * as dompack from 'dompack';
import { RPCFormBase } from '@mod-publisher/js/forms';

dompack.onDomReady(() => {
  // kill the scroll on the footer contact form
  dompack.qSA('footer .c-form .w-formwidget .wh-anchor').forEach((el) => {
    el.scrollIntoView = function () {};
  });

  // update the label of the footer contact form
  const footerSubmitButton = dompack.qS('footer.footer .wh-form__button--submit .wh-form__buttonlabel');
  if (footerSubmitButton) {
    footerSubmitButton.innerText = 'Bel mij terug';
  }
});

// no idea why we need to this for a 'widget form', it's
// to prevent "index.ts:10 No RPC handler registered for this form!"
export default class FooterContactForm extends RPCFormBase {}
