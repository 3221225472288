import { LitElement, html } from 'lit';
import * as whintegration from '@mod-system/js/wh/integration';
import 'dompack/browserfix/reset.css';
import * as interfaces from '../../shared/js/interfaces';

class HeaderMedia extends LitElement {
  type!: string; // video, image
  videoid!: string;
  title!: string;
  subtitle!: string;
  ishome!: string;
  image!: interfaces.Image;
  textbackgroundimage!: interfaces.Image;
  sideimage!: interfaces.Image;

  static properties = {
    title: { type: String },
    subtitle: { type: String },
    type: { type: String },
    video: { type: String },
    videoid: { type: String },
    ishome: { type: String },
    image: { type: Object },
    textbackgroundimage: { type: Object },
    sideimage: { type: Object },
  };

  // forces shadow DOM to be disabled
  createRenderRoot() {
    return this;
  }

  render() {
    const isHome = this.ishome === 'true';
    // if (this.type == 'video') {
    if (isHome) {
      return html`
        <div class="c-home">
          <div class="c__container pageheader pageheader--video">
            <div class="pageheader__videocontainer">
              ${this.type == 'video'
                ? html`<div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Video ophalen...</span>
                    </div>
                    <div class="pageheader__videoiframecontainer">
                      <iframe
                        class="pageheader__videoiframe"
                        src="https://player.vimeo.com/video/${this.videoid}?title=0&byline=0&portrait=0&background=1"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowfullscreen></iframe>
                    </div>`
                : html`${this.image
                    ? html`<img class="c_bgimage" src="${this.image.link}" style="${this.image.imagecss}" />`
                    : ''}`}
            </div>
            <div class="c__video-overlay" style="background-image:url('${this.textbackgroundimage.link}')">
              <div class="c__text">
                <h1>${this.title}</h1>
                <p>${this.subtitle}</p>
              </div>
            </div>
          </div>
          <div class="c__mobiletext">
            <div class="container-xl">
              <p>${this.subtitle}</p>
            </div>
          </div>
        </div>
      `;
    }

    return html`<div class="c__nonhome">
      ${this.sideimage ? html`<img class="c_imageoverlay" src="${this.sideimage.link}" />` : ''}
      <div class="c__container pageheader pageheader--video">
        ${this.type == 'image' && this.image
          ? html`<img class="c_bgimage" src="${this.image.link}" style="${this.image.imagecss}" />`
          : ''}
        ${this.type == 'video' && this.videoid
          ? html` <div class="pageheader__videocontainer">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Video ophalen...</span>
              </div>
              <div class="pageheader__videoiframecontainer">
                <iframe
                  class="pageheader__videoiframe"
                  src="https://player.vimeo.com/video/${this.videoid}?title=0&byline=0&portrait=0&background=1"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen></iframe>
              </div>
            </div>`
          : ''}
        <div class="container-xl c__containerinner">
          <h1 class="c__title">${this.title}</h1>
          ${this.subtitle ? html`<h3 class="c__subtitle h3">${this.subtitle}</h3>` : ''}
        </div>
      </div>
    </div>`;
  }
}

customElements.define('c-header-media', HeaderMedia);
