import * as dompack from 'dompack';
import Swiper from 'swiper';
import { Navigation, EffectFade } from 'swiper/modules';

dompack.onDomReady(() => {
  new Swiper('.w-slideshow .swiper', {
    modules: [Navigation, EffectFade],
    loop: true,
    // effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
});
