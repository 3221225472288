import * as dompack from 'dompack';
import $ from 'cash-dom';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-team-member')) {
    return;
  }

  $('.c-portrait__container').appendTo($('.w-title'));
  $('.js-page-team-move-to-title').appendTo($('.w-title'));
});
